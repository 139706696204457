import React, { useState, useEffect } from "react";
import "../photos/photos.css";
import FindRace from "../common/findRaceFooter/findrace";
import { leaderboard } from "../../assets/leaderboard/leaderboardtxt";
import PageHead from "../common/pageHead/pageHead";
import Select from "react-select";
import WinnerList from "./WinnerList";
import { number } from "yup";
const options = [
  { value: "22", label: "2022-23" },
  { value: "23", label: "2023-24" },
  { value: "24", label: "2024-25" },
];

const LeaderBoard = () => {
  const [selectedValue, setSelectedValue] = useState("2024-25");
  const [selectedOption, setSelectedOption] = useState("24");
  const [selected, setSelected] = useState([{ value: "24", label: "2024-25" }]);
  const handleChange = (selected) => {
    setSelected(selected);
    setSelectedValue(selected.label);
    setSelectedOption(selected.value);
  };
  const [selectedArr, setSelectedArr] = useState(leaderboard.twotwo);

  useEffect(() => {
    if (selectedOption === "22") {
      setSelectedArr(leaderboard.twotwo);
    }
    if (selectedOption === "23") {
      setSelectedArr(leaderboard.twothree);
    }
    if (selectedOption === "24") {
      setSelectedArr(leaderboard.twofour);
    }
  }, [selectedOption]);

  function LeaderBoardTable({ head, data }) {
    return (
      <div>
        <div className="leaderboard-head">
          <p>{head}</p>
        </div>
        <div className="leaderboard-data">
          <div className="leaderboard-data-head">
            <label style={{ width: "25%" }}>Ranking</label>
            <label style={{ width: "40%" }}>Name</label>
            <label style={{ textAlign: "center", width: "30%" }}>
              Total Points
            </label>
          </div>
          {data.map((item) => (
            <div key={item.id} className="leaderboard-data-list">
              <div style={{ width: "20%", paddingLeft:"20px" }} >
              <label className="rank-label">{item.id}</label>
              </div>
              <label style={{ width: "50%" }}>{item.name}</label>
              <label style={{ textAlign: "start", width: "15%" }}>
                {item.points}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '2px 0',
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "0px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    }),
  };

  return (
    <div className="photos_wrapper">
      <div className="photos_header_div">
        <div className="photos_header_inner_div"></div>
      </div>
      <PageHead
        page_name="Leaderboard"
        page_head={leaderboard.leaderboardhead}
        text_1={leaderboard.leaderboardubtext}
        text_2={null}
        text_3={null}
        media={
          "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1440X434%2BLeaderboard_11zon.webp"
        }
        media_type="image"
      />
      <div className="photos_cards_div_leaderboard">
        <div className="photos_dropdown_leaderboard">
          <p>Select Year</p>
          <Select
            isSearchable={false}
            value={selected}
            onChange={handleChange}
            options={options}
            styles={customStyles}
          />
        </div>
      </div>
      <div className="leaderboard">
        <LeaderBoardTable head="Male Participants" data={selectedArr.male} />
        <LeaderBoardTable
          head="Female Participants"
          data={selectedArr.female}
        />
      </div>
      <WinnerList />
      <FindRace
        heading="Unleashing the Power of Resistance!"
        para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."
        btn_text="Find Your Race"
      />
    </div>
  );
};

export default LeaderBoard;

