import React, { useState, useEffect } from "react";
import "./findYourPastRaceCard.css";
import {useNavigate } from "react-router-dom";

const FindPastYourRaceCard = ({ data }) => {
  const navigate = useNavigate() 

  return (
    <>
      {data.map((item) => {
        return (
          <div className="findpastyourrace_card">
            <div className="findpastyourrace_card_img_div">
              <img src={item.image} alt="image" />
            </div>
            <div className="findpastyourrace_card_info_div">
              <p className="findpastyourrace_card_title">{item.city}</p>
              <p className="findpastyourrace_card_date">{item.date}</p>
              <div className="findpastyourrace_card_btns">
              <button className="findpastyourrace_card_know_more" onClick={() => navigate(`/${item.knowMoreLink}`)}>
                  Know More
                </button>
                
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FindPastYourRaceCard;
