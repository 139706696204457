import { useState, useEffect } from "react";
import Competitive from "./competitive";
import Non_Comp from "./non_comp";
import Junior from "./junior";
import "./categories.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Master from "./master";
const options = [
  { value: "competitive", label: "Competitive" },
  { value: "master", label: "Master" },
  { value: "non_comp", label: "Non Competitive" },
  { value: "junior", label: "Junior" },
  // { value: 'corporate', label: 'Corporate' },
];

function Categories(props) {
  const [selectedValue, setSelectedValue] = useState(props?.view);
  const [selectedOption, setSelectedOption] = useState(props?.view);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    if (props.view === "competitive") {
      setSelected([{ value: "competitive", label: "Competitive" }]);
    } else if (props.view === "non_comp") {
      setSelected([{ value: "non_comp", label: "Non Competitive" }]);
    } else if (props.view === "junior") {
      setSelected([{ value: "junior", label: "Junior" }]);
    } else {
      setSelected([{ value: "master", label: "Master" }]);
    }
    // else {
    //     setSelected([{ value: 'corporate', label: 'Corporate' }]);
    // }
  }, []);
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState(props?.view);
  const [isMobileView, setIsMobileView] = useState(false);
  const handleChange = (selected) => {
    setSelected(selected);
    setSelectedValue(selected.value);
    setSelectedOption(selected.label);
    setActiveCategory(selected.value);
    // if (selected.value === 'corporate') {
    //     navigate('/categories/corporate');
    // }
    if (selected.value === "non_comp") {
      navigate("/categories/non_comp");
    } else if (selected.value === "junior") {
      navigate("/categories/junior");
    } else if (selected.value === "master") {
      navigate("/categories/master");
    } else {
      navigate("/categories");
    }
  };
  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    // if (category === 'corporate') {
    //     navigate('/categories/corporate');
    // }
    if (category === "non_comp") {
      navigate("/categories/non_comp");
    } else if (category === "junior") {
      navigate("/categories/junior");
    } else if (category === "master") {
      navigate("/categories/master");
    } else {
      navigate("/categories");
    }
  };

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    if (pathParts[1] === "categories") {
      // if (pathParts[2] === 'corporate') {
      //     setActiveCategory("corporate");
      //     setSelected([{ value: 'corporate', label: 'Corporate' }])
      // }
      if (pathParts[2] === "junior") {
        setActiveCategory("junior");
        setSelected([{ value: "junior", label: "Junior" }]);
      } else if (pathParts[2] === "non_comp") {
        setActiveCategory("non_comp");
        setSelected([{ value: "non_comp", label: "Non Competitive" }]);
      } else if (pathParts[2] === "master") {
        setActiveCategory("master");
        setSelected([{ value: "master", label: "Master" }]);
      } else {
        setActiveCategory("competitive");
        setSelected([{ value: "competitive", label: "Competitive" }]);
      }
    }
  }, [location.pathname]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 770);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const customStyles = {
    indicatorSeparator: (provided) => ({
      display: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "0px",
      border: "1px solid #aeaeae",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #aeaeae",
      },
      "&:focus": {
        border: "1px solid #aeaeae",
        boxShadow: "none",
      },
      "&:active": {
        border: "1px solid #aeaeae",
        boxShadow: "none",
      },
      "&:focus-visible": {
        border: "1px solid #aeaeae",
        boxShadow: "none",
        outline: "none",
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    }),
  };

  return (
    <>
      <div className="categoriesContainer">
        <h1>Find the Spot</h1>
        <p>
          The Maruti Suzuki Arena Devils Circuit is a unique race where every
          participant, irrespective of age, gender, fitness level, finds a place
          for themselves and a community to be a part of.
        </p>
        <p>
          Through the various categories of participation, you can find what you
          are looking for.
        </p>
        {isMobileView ? (
          <>
            <Select
              isSearchable={false}
              value={selected}
              onChange={handleChange}
              options={options}
              styles={customStyles}
            />
          </>
        ) : (
          <>
            <div className="navbar">
              <div
                className={
                  activeCategory === "competitive" ? "tabActive" : "disable"
                }
                onClick={() => handleCategoryChange("competitive")}
              >
                Competitive
              </div>
              <div
                className={
                  activeCategory === "master" ? "tabActive" : "disable"
                }
                onClick={() => handleCategoryChange("master")}
              >
                Master
              </div>
              <div
                className={
                  activeCategory === "non_comp" ? "tabActive" : "disable"
                }
                onClick={() => handleCategoryChange("non_comp")}
              >
                Non Competitive
              </div>
              <div
                className={
                  activeCategory === "junior" ? "tabActive" : "disable"
                }
                onClick={() => handleCategoryChange("junior")}
              >
                Junior
              </div>
              {/* <div className={activeCategory === "corporate" ? "tabActive" : "disable"} onClick={() => handleCategoryChange('corporate')}>Corporate</div> */}
            </div>

            <div className="border"></div>
          </>
        )}
      </div>
      {activeCategory === "competitive" && <Competitive />}
      {activeCategory === "non_comp" && <Non_Comp />}
      {activeCategory === "junior" && <Junior />}
      {/* {activeCategory === 'corporate' && <Corporate />} */}
      {activeCategory === "master" && <Master />}
    </>
  );
}

export default Categories;
