import React from 'react';
import './sponsor.css';

const Sponsor = () => {
    const handleSponsorClick = (website) => {
        window.open(website, '_blank', 'noopener,noreferrer');
    };

    const sponsors = [
        { 
            image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/jockey.png", 
            alt: "Jockey", 
            website: "https://www.jockey.in/"
        },
        { 
            image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/bisleri+(2).png", 
            alt: "Bisleri", 
            website: "https://www.bisleri.com/"
        },
        { 
            image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/GMB+(1).png", 
            alt: "Get My Bib", 
            website: "https://www.getmybib.com/"
        },
        { 
            image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/BF.png", 
            alt: "BF", 
            website: "https://www.bunkerfit.com/"
        },
    ];

    return (
        <div className="sponsor-container">
            {sponsors.map((sponsor, index) => (
                <div key={index} className="sponsor-item">
                    <img 
                        src={sponsor.image} 
                        alt={sponsor.alt} 
                        className="sponsor-image" 
                        onClick={() => handleSponsorClick(sponsor.website)}
                        style={{cursor: 'pointer'}}
                    />
                </div>
            ))}
        </div>
    );
}

export default Sponsor;