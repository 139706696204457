import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import './mobileSponsor.css';
import "slick-carousel/slick/slick-theme.css";

// Array of objects containing image URLs and headings
const sponsors = [
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/jockey.png", heading: "Apparel Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/bisleri+(2).png", heading: "Hydration Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/GMB+(1).png", heading: "Ticketing Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/BF.png", heading: "Wellness Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/jockey.png", heading: "Apparel Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/bisleri+(2).png", heading: "Hydration Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/GMB+(1).png", heading: "Ticketing Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/BF.png", heading: "Wellness Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/jockey.png", heading: "Apparel Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/bisleri+(2).png", heading: "Hydration Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/GMB+(1).png", heading: "Ticketing Partner" },
   { image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/BF.png", heading: "Wellness Partner" },
];

function MobileSponsors({ showBorder }) {
    return (
        <div className={`movingSponsorBanner ${showBorder ? 'showBorderTop' : ''}`}>
            <div className="sliderSponsorBanner">
                <div className="slide-sponsor-track">
                    {sponsors.map((sponsor, index) => (
                        <div key={index} className="sponsor-item">
                            {/* <h3 className="sponsor-heading">{sponsor.heading}</h3> */}
                            <div className="slide-sponsor">
                                <img src={sponsor.image} height="400" width="250" alt={sponsor.heading} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MobileSponsors;